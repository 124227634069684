import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  iconsUpload: {
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  buttonRepairs: {
    backgroundColor: '#21a1f9',
    color: '#fff',
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      fontSize: '13px',
    },
  },
  buttonRepairsCancel: {
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      fontSize: '13px',
    },
  },
  titleFont: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  pointer: {
    cursor: 'pointer',
    marginLeft: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
  },
  whiteBox: {
    margin: '8px 16px',
    padding: '8px 16px',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid lightgray',
    overflow: 'hidden',
    gap: '16px',
  },
  clickableBox: {
    cursor: 'pointer',
  },
  label: {
    color: '#666',
    fontSize: '12px',
  },
  info: {
    gap: '4px',
  },
  pendingLabel: {
    fontSize: 20,
    color: '#828282',
    textDecoration: 'underline',
  },
  completeLabel: {
    fontSize: 20,
    color: '#219653',
    textDecoration: 'underline',
  },
  table: {
    borderRadius: 12,
    overflow: 'hidden',
    border: '1px solid lightgray',
  },
  tableHeader: {
    display: 'flex',
    backgroundColor: 'rgba(211,211,211,0.3)',
    padding: '10px 15px',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  tableLine: {
    borderTop: '1px solid lightgray',
    padding: '10px 15px',
  },
  iconAdd: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    display: 'flex',
  },
  iconAddDisabled: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.24)',
  },
  iconDelete: {
    height: '25px',
    width: '25px',
    cursor: 'pointer',
  },
});
