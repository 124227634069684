/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Dashboard, useUppy } from '@uppy/react';
import { dataProvider } from 'providers/dataProvider';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { makeStyles } from '@material-ui/core/styles';
import ImageViewerRepair from './master-sheet-image-viewer-repair';

const bucketURL =
  'https://bucketeer-2acefe00-ecc3-4ddf-9171-a5622d2760f2.s3.amazonaws.com/public/';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '80px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  dialog: {
    height: '90vh',
  },
  uploader: {
    height: '30vh',
  },
});

export const MasterSheetRepairPhotoUpload = (props: any) => {
  const classes = useStyles();
  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [imageSelected, setImageSelected] = useState<any>();
  const [photos, setPhotos] = useState<any>([]);

  const onBeforeUpload = files => {
    Object.keys(files).map((key: any) => {
      files[key].fileName = `job-repair-photo-${new Date().getTime()}-${
        files[key].name
      }`;
    });
    return files;
  };

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      onBeforeUpload,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
      },
    }).use(AwsS3, {
      getUploadParameters(file: any) {
        console.log(file);
        return dataProvider
          .create('/uploads/sign', {
            data: {
              contentType: file.type,
              objectName: `${file.fileName}`,
            },
          })
          .then(({ data }) => {
            return {
              method: 'PUT',
              url: data.signedUrl,
              fields: data.fields,
              headers: {
                'Content-Type': file.type,
              },
            };
          });
      },
    });
  });

  useEffect(() => {
    uppy.on('complete', _response => {
      _response.successful.map(async (itemResponse: any) => {
        const jobPhasePhotoPayload = {
          fileName: itemResponse.fileName,
          repairPhotoType: 'Photo',
          jobRepairId: props.selectedReceivable,
        };
        await dataProvider.create('/job-repair-photo', {
          data: jobPhasePhotoPayload,
        });
        getPhotos(props.selectedReceivable);
      });
      uppy.reset();
    });
  }, []);

  const getPhotos = async id => {
    const repairPayment: any = await dataProvider.getOne('job-repair', {
      id,
    });
    setPhotos(repairPayment.data.jobRepairPhotos);
  };

  useEffect(() => {
    getPhotos(props.selectedReceivable);
  }, [props.selectedReceivable]);

  console.log(photos);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">Uploads</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          {props.canPaid && (
            <Dashboard
              disableThumbnailGenerator
              showLinkToFileUploadResult={false}
              uppy={uppy}
              height={100}
            />
          )}
        </DialogContentText>
        <Box>
          <Box>
            {photos.length ? (
              photos.map((repairPhoto: any, i) => {
                return (
                  <img
                    className={classes.img}
                    onClick={() => {
                      setImageSelected(repairPhoto);
                      setImageViewerOpen(true);
                    }}
                    src={repairPhoto.fileUrl}
                    key={i}
                  />
                );
              })
            ) : (
              <Typography>No uploads available.</Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <ImageViewerRepair
        canPaid={props.canPaid}
        imageSelected={imageSelected}
        open={imageViewerOpen}
        getPhotos={() => getPhotos(props.selectedReceivable)}
        handleClose={() => setImageViewerOpen(false)}
      />
    </Dialog>
  );
};

export default MasterSheetRepairPhotoUpload;
