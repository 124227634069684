import { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { JobRepairNoteDto } from '@vatos-pas/common';
import { Identifier, useDataProvider, useNotify } from 'react-admin';
import { Show } from 'components/Show';

type NotesHistoryProps = {
  jobRepairId: Identifier | undefined;
};

export const NotesHistory = ({ jobRepairId }: NotesHistoryProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<JobRepairNoteDto[] | null>(null);

  const getNotes = async () => {
    try {
      setLoading(true);
      const notesResponse = await dataProvider.getList<JobRepairNoteDto>(
        '/job-repair-note',
        {
          filter: {
            jobRepairId,
          },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'createdAt', order: 'DESC' },
        },
      );

      setNotes(notesResponse.data);
    } catch (err) {
      notify('Failed to get notes', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (jobRepairId) {
      getNotes();
    }
  }, [jobRepairId]);

  return (
    <Box width="100%" paddingBottom={1}>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box>
          <Show
            condition={!!notes?.length}
            fallback={<Typography>No notes available.</Typography>}
          >
            <Box pt={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Notes History
              </Typography>
            </Box>
            {notes?.map((item, index) => (
              <Box
                key={item.id}
                mb={2}
                borderTop={index === 0 ? '' : 1}
                borderColor="lightgray"
                pt={2}
              >
                <Box>
                  <Box>
                    <Typography>{`${item.user?.firstName} ${
                      item.user?.lastName
                    } - ${new Date(item.createdAt).toLocaleTimeString(
                      'en-US',
                    )} ${new Date(item.createdAt).toLocaleDateString(
                      'en-US',
                    )}`}</Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography>{item.note}</Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Show>
        </Box>
      )}
    </Box>
  );
};

export default NotesHistory;
