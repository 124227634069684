import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/file-input/dist/style.css';

import React, { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { Dashboard, useUppy } from '@uppy/react';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { makeStyles } from '@material-ui/core/styles';

import { dataProvider } from 'providers/dataProvider';
import ImageViewer from './ImageViewer';
import { useNotify, useRecordContext } from 'react-admin';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_BUMPOUT_TIMESHEET_PHOTOS } from 'providers/permissions';
import { JobRepairPhotoDto } from '../../../../../common/dist';

export type ImageType = 'Photo' | 'Screenshot';

declare module '@uppy/utils' {
  export interface UppyFile {
    fileName: string;
  }
}

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '80px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  dialog: {
    height: '90vh',
  },
  uploader: {
    height: '30vh',
  },
});

type PhotoUploadProps = {
  open: boolean;
  handleClose(): void;
};

export const PhotoUpload: FC<PhotoUploadProps> = ({ open, handleClose }) => {
  const classes = useStyles();
  const notify = useNotify();
  const record = useRecordContext();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [imageSelected, setImageSelected] = useState<JobRepairPhotoDto | null>(
    null,
  );
  const [photos, setPhotos] = useState([] as JobRepairPhotoDto[]);

  const canEditPhotos = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_PHOTOS);
  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';

  const loadPhotos = async () => {
    try {
      const { data } = await dataProvider.getList<JobRepairPhotoDto>(
        isJobPhaseBump ? 'job-phase-bump-photo' : 'job-repair-photo',
        {
          filter: {
            [isJobPhaseBump ? 'jobPhaseBumpId' : 'jobRepairId']:
              (isJobPhaseBump ? record?.id : record?.jobRepairId) || '',
          },
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'id', order: 'DESC' },
        },
      );

      setPhotos(data);
    } catch (error) {
      notify('Failed to load photos', 'error');
    }
  };

  const onBeforeUpload = files => {
    Object.keys(files).map(key => {
      files[key].fileName = `job-repair-photo-${new Date().getTime()}-${
        files[key].name
      }`;
    });
    return files;
  };

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      onBeforeUpload,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
      },
    }).use(AwsS3, {
      getUploadParameters(file) {
        return dataProvider
          .create('/uploads/sign', {
            data: { contentType: file.type, objectName: `${file.fileName}` },
          })
          .then(({ data }) => {
            return {
              method: 'PUT',
              url: data.signedUrl,
              fields: data.fields,
              headers: { 'Content-Type': file.type },
            };
          });
      },
    });
  });

  useEffect(() => {
    if (open) {
      loadPhotos();
    }
  }, [open]);

  useEffect(() => {
    uppy.on('complete', async response => {
      for (const item of response.successful) {
        const response = await dataProvider.create<JobRepairPhotoDto>(
          isJobPhaseBump ? 'job-phase-bump-photo' : 'job-repair-photo',
          {
            data: {
              [isJobPhaseBump ? 'jobPhaseBumpId' : 'jobRepairId']:
                (isJobPhaseBump ? record?.id : record?.jobRepairId) || '',
              fileName: item.fileName,
              ...(!isJobPhaseBump && {
                repairPhotoType: 'Photo',
              }),
            },
          },
        );

        if (response?.data) {
          setPhotos(prevState => {
            if (!prevState) return [response.data];

            const newState = [...prevState];
            newState.push(response.data);
            return newState;
          });
        }
      }

      uppy.reset();
    });
  }, []);

  return (
    <Dialog
      open={open}
      keepMounted={false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">Photos</DialogTitle>
      <DialogContent>
        {canEditPhotos && (
          <DialogContentText id="alert-dialog-desc9ription">
            <Dashboard
              disableThumbnailGenerator
              showLinkToFileUploadResult={false}
              uppy={uppy}
              height={100}
            />
          </DialogContentText>
        )}
        <Box>
          <Box>
            {photos.map(photo => (
              <img
                key={photo.id}
                className={classes.img}
                src={photo?.fileUrl ?? ''}
                onClick={() => {
                  setImageSelected(photo);
                  setImageViewerOpen(true);
                }}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
      <ImageViewer
        imageSelected={imageSelected}
        open={imageViewerOpen}
        loadPhotos={loadPhotos}
        handleClose={() => setImageViewerOpen(false)}
      />
    </Dialog>
  );
};

export default PhotoUpload;
